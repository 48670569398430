import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/program',
    name: 'program',
    component: () => import('../views/ProgramCurrent.vue')
  },
  {
    path: '/program-api',
    name: 'program_api',
    component: () => import('../views/ProgramApi.vue')
  },
  {
    path: '/program-api-history',
    name: 'program_api_history',
    component: () => import('../views/ProgramApiHistory.vue')
  },
  {
    path: '/program-old',
    name: 'program_old',
    component: () => import('../views/ProgramOld.vue')
  },
  
  {
    path: '/program-beta',
    name: 'program_beta',
    component: () => import('../views/ProgramBeta.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/History.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/MenuContact.vue')
  },
  {
    path: '/about',
    name: 'about_program',
    component: () => import('../views/MenuAboutProgram.vue')
  },
  {
    path: '/requirement',
    name: 'about_requirement',
    component: () => import('../views/MenuRequirement.vue')
  },
  {
    path: '/disclaimer',
    name: 'about_disclaimer',
    component: () => import('../views/MenuDisclaimer.vue')
  },
  {
    path: '/links',
    name: 'links',
    component: () => import('../views/MenuLinks.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
  //スクロール位置を指定
  scrollBehavior(to, from, savedPosition){
    if(savedPosition){
      //savedPositionがあるとvueデフォルトのスクロール挙動になるようです（スクロール位置がページ遷移後も保存される）
      return savedPosition
    } else if (to.hash) {
      const position = {}
      position.selector = to.hash
      //ページ内リンクがヘッダーにかかって見えなくならないようオフセット
      position.offset = { y: 120 }
      return position
    } else {
      //savedPositionがなく、かつ、router-linkで＃を使用していない場合はスクロール位置がtopになります。
      return { x:0, y:0 }
    }
  }
})

export default router
