<template>
  <div id="app">
    <svg-icon />
    <router-view/>
  </div>
</template>


<script>
import 'normalize.css'
import SvgIcon from '@/components/atoms/SvgIcon.vue';

export default {
  name: 'App',
  components: {
    SvgIcon,
  },

  data: () => ({
    
  }),

};
</script>
<style lang="scss">
*{
  box-sizing: border-box;
}
#app {
  font-family: 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: #534C3D;
  font-feature-settings: "palt";
}
main{
  min-height: calc(100vh - 150px);
  padding-bottom: 150px;
}
p {
	line-height: 1.75em;
	font-feature-settings : "palt";
  line-break: strict;
}
.text-no-wrap{
  white-space: nowrap;
}

/* リスト */
ul{
  display: flex;
  flex-direction: column;
  padding-inline-start: 1em;
  row-gap: 8px;
}
ul.el_list{
  display: flex;
  flex-direction: column;
  row-gap: 0.75em;
}
ul.el_list__indexpage li{
  font-size: 1.5em;
  margin-bottom: 1em;
	margin-left: 1em;
  list-style-type: unset;
}
ul.el_list__nostyle {
  list-style-type: none;
  padding-inline-start: 0;
}
blockquote{
	display: block;
	margin: 1em;
	padding: 1em 2em;
	background-color:#EEEEEE;
	position: relative;
}
blockquote:before{
  content: "";
  display: block;
  width: 3em;
  height: 3em;
  border-right: 3px double black;
  transform: rotate(45deg);
  left: -2em;
  top: -2em;
  position:absolute;
}
blockquote:after{
  content: "";
  display: block;
  width: 3em;
  height: 3em;
  border-left: 3px double black;
  transform: rotate(45deg);
  right: -2em;
  bottom: -2em;
  position:absolute;
}
/* テキスト */
.el_h2{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  margin: 1em 0 1em 0;
  font-size: 1.75em;
  font-weight: 400;
  letter-spacing: 0.2em;
  padding: 1em;
  margin-bottom: 1em;
}
.el_text__bolder{
  font-weight: 600;
}
/* ラベル */
.el_label__borderBox{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  width: 8em;
  height: 1.25rem;
  font-size: 0.8em;
}
.el_fukidashi{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 2em;
    margin: .6rem auto .5rem;
    padding: 1rem 2em;
    border-radius: 1.5em;
    background-color: #fff171;
    color: #000;
    position: relative;
    box-shadow: 0 2px 2px 0 rgba(0,0,0, .2);
    z-index: 100;
}
.el_fukidashi:before{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: .75rem solid #fff171;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    position: absolute;
    bottom: -.7rem;
    z-index: 99;
}
.el_fukidashi:after{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 14px solid rgba(0,0,0, .2);
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    position: absolute;
    bottom: calc(2px - 4px - .75rem);
    z-index: 98;
}

/* ヘルパー */
.hp_text__nowrap{
  display:inline-flex;
  white-space: nowrap;
}
.hp_noMargin{
  margin: 0;
}
.hp_arrow__migi:after{
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin-top: 0;
  margin-bottom: 1px;
  border-bottom: 3px solid currentColor;
  border-right: 3px solid currentColor;
  transform: rotate(-45deg);
  flex-wrap: wrap;
  align-items: baseline;
}
.hp_disableBtn{
  filter: grayscale(100%);
  pointer-events: none;
}
.hp_disableBtn:hover{
  transform: scale(1);
  cursor: default;
}

@media (max-width: 960px){ /* タブレットサイズ以下 */
  .hp_invisible__tb {
      display: none!important;
  }
}
/* 入力部品 */
.el_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  margin: 0.5rem auto;
  font-weight: bold;
  text-align: center;
  color:white !important;
  background: transparent linear-gradient(180deg, #847248 0%, #90815B 100%) 0% 0% no-repeat padding-box;
  border-radius: .5em;
  border-top: white 1px solid;
  border-left: white 1px solid;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .3);
}
.el_btn.el_btn__big{
  width: fit-content;
  padding: 1rem 2em;
  font-size: 24px;
}
.el_btn:hover{
  text-shadow: 1px 2px 3px rgba(0, 0, 0, .3);
  box-shadow: 2px 6px 18px rgba(0, 0, 0, .2);
}
.el_btn.el_btn__disabled{
  background: none;
  background-color: #ccc !important;
  color: #999 !important;
  box-shadow: none !important;
  cursor: default;
  pointer-events: none;
}
.el_btn.el_btn__disabled:hover{
  text-shadow: none;
  box-shadow: none !important;
  pointer-events: none;
  cursor: default;
}
.el_btn.el_btn__program{
  color:white;
  background: linear-gradient(180deg, #534c3d 0%, #655b46 100%);
}
.el_btn.el_btn__program:hover{
  color:white;
  background: linear-gradient(180deg, #534c3d 0%, #534c3d 100%);
}
.el_btn.el_btn__model{
  color:white;
  background: linear-gradient(180deg, #847248 0%, #90815b 100%);
}
.el_btn.el_btn__model:hover{
  color:white;
  background: linear-gradient(180deg, #847248 0%, #847248 100%);
}
.el_btn.el_btn__shokibo{
  color:white;
  background: linear-gradient(180deg, #f69e00 0%, #f6b400 100%);
}
.el_btn.el_btn__shokibo:hover{
  color:white;
  background: linear-gradient(180deg, #f69e00 0%, #f69e00 100%);
}
.el_btn.el_btn__csv{
  color:white;
  background: linear-gradient(180deg, #555555 0%, #777777 100%);
}
.el_btn.el_btn__csv:hover{
  color:white;
  background: linear-gradient(180deg, #555555 0%, #555555 100%);
}
.el_btn.el_btn__house{
  color:white;
  background: linear-gradient(180deg, #41B8D4 0%, #4ac7e3 100%);
}
.el_btn.el_btn__house:hover{
  color:white;
  background: linear-gradient(180deg, #41B8D4 0%, #41B8D4 100%);
}
.el_btn__comingsoon{
  filter: grayscale(100%);
  pointer-events: none;
}
.el_btn__comingsoon::before{
  content: "準備中";
  display: inline-flex;
  font-size: 0.5em;
  color: white;
  background-color: rgb(92, 92, 92);
  transform: rotate(-6deg);
  left: -1em;
  top: -1em;
  position: absolute;
  padding: 0.5em 1em;
}
/* ナビ */
.bl_header_nav{
  display: flex;
  align-items: center;
  column-gap: 32px;
  width: 100%;
  height: 46px;
  margin: 0;
  padding: 0 32px;
  background-color:#555555;
  
  z-index: 10;
  left: 0;
  top: -2px;
  position: -webkit-sticky;
  position: sticky;
}
.bl_header_nav a{
  font-size: 14px;
  color: white !important;
}
.bl_header_nav a:first-of-type{
    margin-right: auto;
}
/* コンテナ */
.bl_container{
  display: flex;
  width: 90%;
  max-width: 1500px; 
  margin: 0 auto 64px;
}
.bl_container.bl_container__justifyCenter{
  justify-content: center;
}
.bl_container.bl_container__column{
  display: flex;
  flex-direction: column;
}
.bl_container.bl_container__2column{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 50%));
  justify-content: center;
}
@media (max-width: 960px){ /* タブレットサイズ以下 */
  .bl_container.bl_container__2column{
    grid-template-columns: 1fr;
}
}
.bl_container__2column_child{
  display: flex;
  justify-content: center;
  padding: 0 32px;
}

/* カードレイアウト */

.bl_card{
	display: flex;
  flex-wrap: wrap;
	margin: 0.5em;
	padding: 1.5em 2em;
	border: #ccc solid 1px;
	background-color:white;
	position: relative;
}
.bl_card:hover{
	box-shadow: 0 0 15px rgba(0,0,0 ,0.05);
}
.bl_card.bl_card__2column{
  display: grid;
  grid-template-columns: minmax(300px, auto) minmax(300px, 100%);
  column-gap: 32px;
  row-gap: 32px;
}
.bl_card.bl_card__2column .bl_card__2column_title{
  grid-column: 1 / -1;
}
.bl_card img{
	display: block;
	max-width: 100%;
	margin: 0 auto;
	object-fit: contain;
}
.bl_card h3{
	font-size: 1.5em;
}
.bl_card h4{
	margin: 0.5em 0;
}
.bl_card dl{
  display: block;
  margin-bottom: 2em;
}
.bl_card dt{
  display: inline;
  margin-bottom: 0.25em;
  font-weight: bolder;
}
.bl_card dd {
  display: inline;
  margin-inline-start: 0;
  font-size: 0.8em;
  color:#777;
}
.bl_card dd span{
  float: right;
}
.bl_card .btn_big{
  box-sizing: border-box;
  display: block;
  max-width: 100%;
}

@media (max-width: 960px){ /* タブレットサイズ以下 */
  .bl_card.bl_card__2column{
    grid-template-columns: 1fr;
  }
}
/* 例示用の灰色のボックス */
.bl_example{
  margin-top: 16px;
  padding: 1em 1.5em;
  background-color:#eee;
}
/* 注意書き用の赤いボックス */
.bl_notice{
  width: fit-content;
  margin-right: .5em;
  padding: 1em;
  background-color:rgba(240, 116, 182, .1);
}

/* 重要なお知らせ枠 */
.bl_announce {
  display: grid !important;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  column-gap: 2rem;
  row-gap:2rem;
  max-width: 1000px;
  padding: 1rem 2rem;
}
.bl_announce.bl_announce__yellow {
  border: none;
  background-color: #fff171;
}
.bl_announce img{
  width:100%;
  max-width:600px;
  min-width: 300px;
  margin:0 auto;
  background-color: #efefef; 
  border-radius: .5rem;
}
.bl_announce .bl_announce_note{
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}
.bl_announce_note h3{
  margin-bottom: 0;
}
.bl_announce_note p{
  margin: 0;
}

.bl_announce_note ul{
  margin: 0;
  font-size: .8rem;
}
.bl_announce_note ul li{
  margin-top: .75rem;
  line-height: 1.5;
}

/* 横線のみの表 */
.bl_linedTable{
  font-size: 0.9em;
  border-collapse: collapse;
}
.bl_linedTable th,
.bl_linedTable td{
  padding: 2px 0 2px 0;
  text-align:left;
  border-bottom: 1px solid #ccc;
}

.bl_linedTable tr th:first-of-type,
.bl_linedTable tr td:first-of-type{
  padding-right: 2em;
}
/* リンク */
a{text-decoration: none;}
a:link { color: #534C3D; }
a:visited { color: #534C3D; }
a:hover {
  color: #2d291f;
  font-weight: 600;
}
a:active { color: #534C3D; }
a.el_link__migi{
  display: inline-flex;
  align-items: center;
  column-gap:8px;
  padding: 0 4px 2px 22px;
  position: relative;
}
a.el_link__migi:before{
  content: "";
  display: inline-block;
  width: 0.3em;
  height: 0.3em;
  margin-bottom: 1px;
  border-bottom: 3px solid #857348;
  border-right: 3px solid #857348;
  transform: rotate(-45deg);
  position: absolute;
  left: 6px;
}
a.el_link__migi.el_link__migi__multi{
  flex-wrap: wrap;
  align-items: baseline;
}
a.el_link__migi.el_link__migi__multi:before{
  margin-top: 4px;
}

/* イメージバック */
.bl_indexImgWrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
	position: relative;
}
.bl_indexImgWrapper_content{
  display: grid;
  grid-template-columns: 1fr clamp(300px, 25vw, 500px);
  width: 90%;
  min-width: 80vw;
  padding: 32px 32px;
  column-gap: 32px;
}
.bl_indexImgWrapper h3.el_h3__speechbubble{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin: 16px 0 0;
  padding: 16px 0;
  color: #fff;
  background-color: #857348;
  text-align: center;
  position: relative;
  z-index: 2;
}
.bl_indexImgWrapper h3.el_h3__speechbubble:after{
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 18px solid #857348;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  left: calc(50% - 26px);
  bottom: -18px;
  position: absolute;
}
.el_headerBtn{
	display: inline-flex;
  align-items: center;
  justify-content: center;
	width: 90%;
	height: 80px;
	padding: 0.75em 0;
	font-size: 28px;
  letter-spacing: 6.08px;
  background-color:#F69E00;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
.bl_indexImgWrapper_btn{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 24px;
  width: 100%;
}
.bl_indexImgWrapper_btn .el_headerBtn{
  max-width: 300px;
}
/* 過去のお知らせページ */
.bl_history .bl_container.bl_container__2column{
  grid-template-columns: 300px minmax(400px, 50%);
  column-gap: 32px;
}
/* 更新履歴ブロック */
.bl_history{
  background-color: rgba(255,255,255,0.9);
	font-size: 0.9em;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
}
.bl_history.ly_history {
  height: calc(75vh - 64px);
	max-height: 100%;
  scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/  
	overflow-y: scroll;
	overflow-x: hidden;
  position:relative;
}
.bl_history.ly_history::-webkit-scrollbar {  
  display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}
.bl_history_header{
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;
}
.bl_history_header h3{
  margin: 4px 0 8px;
}
.bl_history_content{
  width: 100%;
  min-height: 100%;
  padding: 16px;
}
.bl_history .bl_history_unit{
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.bl_history .bl_history_unit + .bl_history_unit{
  margin-top: 4px;
  padding-top: 12px;
  border-top: 1px solid #ccc;
}
.bl_history .bl_history_unit.ver_regular .bl_history_unit_date:after{
  content: "現行版";
    display: inline-block;
    width: 5.1em;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    line-height: 1em;
    white-space: nowrap;
    color: white;
    background-color: rgb(71, 166, 214);
    top: -1px;
    right: -7.3em;
    position: absolute;
    padding: calc(0.25em + 1.6px) 1em;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(71, 166, 214);
    border-image: initial;
}
.bl_history .bl_history_unit.ver_beta .bl_history_unit_date:after{
  content: "次期更新版";
    display: inline-block;
    width: 5.1em;
    font-size: 10px;
    font-weight: bolder;
    text-align: center;
    line-height: 1em;
    white-space: nowrap;
    color: white;
    background-color: rgb(240, 116, 102);
    top: -1px;
    right: -7.3em;
    position: absolute;
    padding: calc(0.25em + 1.6px) 1em;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(240, 116, 102);
    border-image: initial;
}
.bl_history .bl_history_unit p{
  margin: 8px 0;
  font-size: 14px;
  line-height: 20px;
}
.bl_history .bl_history_unit ul{
  margin: 8px 0 12px;
  font-size: 14px;
}
.bl_history .bl_history_unit ul li{
  line-height: 1.5;
}
.bl_history .bl_history_unit .bl_history_unit_date{
  display:inline-flex;
  align-items: center;
  justify-content: center;
  width: 8em;
  padding: 0.25em 1em;
  margin-bottom: 0;
  font-size:12px;
  text-align:left;
  line-height:1em;
  border: 1px solid black;
  position: relative;
}

.bl_history .bl_history_unit .bl_history_unit_summary>a{
  margin-left: 1em;
}
.bl_history_archiveLink{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #534C3D;
  position: sticky;
  bottom:0;
  left: 0;
}
.bl_history_archiveLink a{
  color: white !important;
}


/* プログラムページ - 入力補助ツール */
.bl_sheet_table{
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.bl_sheet_table h3{
  margin-bottom: 0;
  padding: 8px 0;
}
.bl_sheet_table h4{
  margin: 0;
  padding: 16px 12px 12px;
}
.bl_sheet_table .bl_sheet_table_row small{
  display: inline-block;
  padding: 4px 4px 0px;
  color: rgb(66, 66, 66);
  font-size: 11px;
}
.bl_sheet_table .bl_sheet_table_row{
  display: flex;
  flex-direction: row;
  width: calc(100% - 16px);
  margin: 0 0.5em 0.5em;
  background-color: white;
}

.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid1{
  width: 10em;
  padding: 4px 8px;
}
.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid1 span{
  display: flex;
  justify-content: baseline;
  width: 100%;
  height: 100%;
  padding: 8px;
  font-size: 16px;
  font-weight: bolder;
  color: #777;
  border-right: 4px solid #ccc;
}

.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid2{
  width: 100%;
  height: 100%;
  padding: 4px 8px;
}
.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid2 p{
  margin: 0;
}
.bl_sheet_table .bl_sheet_table_row_grid2_row{
  display: flex;
  width: 100%;
  padding: 4px 0;
  border-bottom: 1px dotted #ccc;
}
.bl_sheet_table .bl_sheet_table_row_grid2_row .bl_sheet_table_row_grid2_row_title__currentVersion{
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
}
.bl_sheet_table .bl_sheet_table_row_grid2_row .bl_sheet_table_row_grid2_row_title__currentVersion:after{
  content: "現行版から変更なし";
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height:1.4em;
  padding: 0.2em 1.5em;
  font-size:0.5em;
  font-weight: 600;
  white-space: nowrap;
  background-color: #928976;
  color: white;
}

.bl_sheet_table .bl_sheet_table_row_grid2_row:last-child{
  border-bottom: none;
}
.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid2 .bl_sheet_table_row_grid2_row.underline{
  margin-bottom: 8px;
  border-bottom: 2px solid #ccc;
}
.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid2 a{
  row-gap:2px;
  margin: 4px;
}
.bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid2 .bl_sheet_table_row_grid2_row p{
  color: rgb(151, 151, 151);
}
.bl_sheet_table .lbl_note{
  font-weight: bolder;
}
.bl_sheet_table .lbl_tool{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  min-width: 8em;
  height: 26px;
  margin-right: 8px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  color: white;
  background-color: #41B8B6;
}
.bl_sheet_table .lbl_appri{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  min-width: 8em;
  height: 26px;
  margin-right: 8px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  color: white;
  background-color: #F69E00;
}
.bl_sheet_table .lbl_doc{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  min-width: 8em;
  height: 26px;
  margin-right: 8px;
  padding: 4px 8px;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  color: white;
  background-color: #777;
}
.bl_sheet_table .lbl_history{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 10em;
  min-width: 10em;
  margin-left: auto;
  padding: 4px 0 4px 8px;
  font-size: 10px;
  font-weight: bolder;
  text-align: center;
  white-space: nowrap;
  border-left: 1px dotted #ccc;
  color: #555;
}
.bl_sheet_table .bl_sheet_table_row_grid2_row[id]{
  display: flex;
}
@media (max-width: 960px){ /* タブレットサイズ以下 */
  .bl_indexImgWrapper_btn{
    flex-direction: column;
    align-items: center;
  }
  .bl_sheet_table .bl_sheet_table_row{
    flex-direction: column;
  }
  .bl_sheet_table .bl_sheet_table_row_grid2_row{
    flex-direction: column;
    row-gap: 4px;
  }
  .bl_sheet_table .bl_sheet_table_row .bl_sheet_table_row_grid1 span{
    border-right: none;
  }
  .bl_announce {
    display: grid !important;
    grid-template-columns: 1fr;
  }
}


@media (max-width: 768px){ /* タブレットサイズ以下 */
  .bl_announce {
    width: 90%;
    margin: 1rem auto;
    padding: 1rem;
  }
}
</style>
